// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.jsx");
}
// REMIX HMR END

import { Links, Meta, Outlet, Scripts, ScrollRestoration, useNavigate, useLocation, useLoaderData } from '@remix-run/react';
import { ClientOnly } from 'remix-utils';
import { useEffect, useState } from 'react';
import { ConfigProvider, Layout, Menu, message, theme, Button } from 'antd';
import en_US from 'antd/locale/en_US';
import styles from './styles/index.css';
import lightbox from './styles/lightbox.css';
import ApiService from './services/ApiService';
import { getSession } from './session';
import logo from './images/TDG LOG NEW.png';
const {
  Header,
  Content,
  Footer,
  Sider
} = Layout;
export function links() {
  return [{
    rel: 'stylesheet',
    href: styles
  }, {
    rel: 'stylesheet',
    href: 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css'
  }, {
    rel: 'stylesheet',
    href: lightbox
  }];
}
function getItem(label, key, icon, children) {
  return {
    label,
    key,
    icon,
    children
  };
}
export const loader = async ({
  params,
  request
}) => {
  const session = await getSession(request.headers.get('Cookie'));
  const companyId = session.get('companyId');
  const comUserId = session.get('comUserId');
  if (companyId) {
    return {
      companyId: companyId,
      comUserId
    };
  }
  return {
    data: null
  };
};
export default function App() {
  _s();
  const location = useLocation();
  const {
    pathname
  } = location;
  const initialItems = [getItem('Campaigns', 'campaign/select', null, null), getItem('Customer Care', 'customers', null), getItem('Setting', 'sub1', null, [getItem('Brands', 'settings/brands', null, null), getItem('Point Shop', 'settings/pointshop', null, null), getItem('Rewards', 'settings/rewards', null, null), getItem('Code Generator', 'settings/code/generator?tab=genList', null, null)]), getItem('Item Delivery', 'item/delivery/management', null, null), getItem('Rewards Transfer', 'rewards/transfer?type=RECEIPT', null, null)];
  const [items, setItems] = useState(initialItems);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const {
    token: {
      colorBgContainer
    }
  } = theme.useToken();
  const {
    companyId,
    campaignId,
    comUserId
  } = useLoaderData();
  useEffect(() => {
    // RB company id
    if (companyId === '9d568e90-3df7-4c1f-b1e8-daa825b4bb6a') {
      // rb specific user id
      if (comUserId === '0a87a930-8d56-486f-b877-4dd73bb2d845') {
        setItems([getItem('OTP Page', 'token/management', null)]);
      } else if (comUserId === '1a70ab3b-7ca3-412b-8c45-570b7e2f2e41') {
        setItems([getItem('Customer Care', 'customers', null)]);
        // rb user for otp page only
      } else {
        setItems([getItem('Campaigns', 'campaign/select', null, null), getItem('Customer Care', 'customers', null), getItem('Rewards', 'settings/rewards', null, null), getItem('Item Delivery', 'item/delivery/management', null, null)]);
      }
      // Jomkik customer report
    } else if (companyId === 'b70f1924-58cc-4e2c-9ace-98e0e3e05b81') {
      setItems([...initialItems, getItem('Customer Report', 'customer/report', null)]);
    } else {
      setItems(initialItems);
    }
  }, [pathname, campaignId, comUserId]);
  useEffect(() => {
    if (companyId) {
      localStorage.setItem('drive-companyId', companyId);
      // RB company id
      if (companyId === '9d568e90-3df7-4c1f-b1e8-daa825b4bb6a') {
        // rb specific user id
        if (comUserId === '0a87a930-8d56-486f-b877-4dd73bb2d845') {
          setItems([getItem('OTP Page', 'token/management', null)]);
        } else if (comUserId === '1a70ab3b-7ca3-412b-8c45-570b7e2f2e41') {
          setItems([getItem('Customer Care', 'customers', null)]);
          // rb user for otp page only
        } else {
          setItems([getItem('Campaigns', 'campaign/select', null, null), getItem('Customer Care', 'customers', null), getItem('Rewards', 'settings/rewards', null, null), getItem('Item Delivery', 'item/delivery/management', null, null)]);
        }
      }
    }
  }, [companyId]);
  const logout = async () => {
    try {
      await ApiService.put(`/api/operator/auth`);
      navigate(`/?companyId=${localStorage.getItem('drive-companyId')}`);
      setTimeout(() => {
        localStorage.removeItem('drive-companyId');
      }, 500);
    } catch (error) {
      console.error('Error:', error);
      message.error(error.message);
    }
  };
  useEffect(() => {
    if (comUserId === '0a87a930-8d56-486f-b877-4dd73bb2d845') {
      return navigate('/token/management');
    }
  }, [comUserId, navigate]);

  // console.log('items', items)

  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <ClientOnly>
          {() => <>
              <ConfigProvider locale={en_US}>
                <Layout style={{
              minHeight: '100vh'
            }}>

                  {pathname === '/' || pathname === '/register' ? null : <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>

                      <div className="demo-logo-vertical" />
                      <Menu theme="dark" defaultSelectedKeys={['campaign']} mode="inline" items={items.map(e => {
                  if (e.children) {
                    return {
                      ...e,
                      label: e.label,
                      children: e.children.map(i => {
                        return {
                          ...i,
                          label: i.label
                        };
                      })
                    };
                  }
                  return {
                    ...e,
                    label: e.label
                  };
                })} onClick={item => {
                  navigate(`/${item.key}`);
                }} />

                    </Sider>}


                  <Layout>
                    <Header style={{
                  background: colorBgContainer
                }} className="d-flex justify-content-between">

                      <div>
                        <img src={logo} alt="logo" style={{
                      objectFit: 'contain',
                      width: '7rem'
                    }} />

                      </div>
                      <div>
                        {pathname === '/' || pathname === '/register' ? null : <Button onClick={() => logout()}>Logout</Button>}

                      </div>
                    </Header>
                    <Content>
                      <Outlet />
                    </Content>
                    <Footer style={{
                  textAlign: 'center'
                }}>

                      The D Group ©{new Date().getFullYear()}
                    </Footer>
                  </Layout>
                </Layout>
              </ConfigProvider>
            </>}

        </ClientOnly>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>;
}
_s(App, "35zTl1O7xTbrEhb5EahnX+a1Kig=", false, function () {
  return [useLocation, useNavigate, theme.useToken, useLoaderData];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;